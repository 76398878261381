import { Suspense, useEffect, useMemo, useRef, useState } from 'react';

import { LogoutOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Flex, Layout, MenuProps, Modal, Spin, Typography } from 'antd';
import { FaUser, FaUserLock } from 'react-icons/fa';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import HeaderTranslation from 'components/header-translations';
import { USER_ROLES_DROPDOWN_LIST } from 'config/constants';
import { StorageService } from 'services/storage.service';

// eslint-disable-next-line import/no-cycle
import { LayoutRouts } from '../../config/routing';
import { useAuth } from '../../contexts/AuthProvider';
import Sidebar from '../navigations/sidebar/Sidebar';

import './index.scss';

const { Header, Content } = Layout;

const DefaultLayout = () => {
	const auth = useAuth();
	const navigate = useNavigate();
	const userRole = StorageService.get('user-role');
	const { user } = auth;

	const settingItems: MenuProps['items'] = useMemo(
		() => [
			{
				key: 'changePassword',
				label: 'Change Password',
				icon: <FaUserLock />,
				onClick: () => navigate('/change-password')
			},
			{
				key: 'userProfile',
				label: 'Profile',
				icon: <FaUser />,
				onClick: () => navigate('/user-profile')
			},
			{
				key: 'logout',
				label: 'Logout',
				icon: <LogoutOutlined />,
				onClick: () => {
					Modal.confirm({
						title: 'Are you sure you want to logout?',
						onOk: async () => {
							await auth.logout();
							navigate('/auth/login');
						},
						okText: 'Yes',
						okType: 'danger',
						cancelText: 'No'
					});
				},
				danger: true
			}
		],
		[]
	);

	return (
		<Layout className='overflow-hidden h-lvh'>
			<Sidebar />

			<Layout>
				<Header className='h-20 w-full flex items-center justify-end sticky top-0 z-10 cursor-pointer'>
					<HeaderTranslation />

					<Dropdown menu={{ items: settingItems }} arrow>
						<div className='flex gap-4 justify-between items-center'>
							<Avatar src={user?.profileImage} shape='circle' size='large' />
							<div className='flex flex-col '>
								<Typography.Title
									level={5}
									style={{
										color: 'white',
										marginBottom: 0,
										marginTop: 0
									}}
								>
									{user?.name}
								</Typography.Title>
								<Typography.Text
									type='secondary'
									style={{
										color: 'gray',
										marginTop: 0
									}}
								>
									{USER_ROLES_DROPDOWN_LIST.find((role) => role.value === userRole)?.label}
								</Typography.Text>
							</div>
						</div>
					</Dropdown>
				</Header>

				<ContentWithScrollTop />
			</Layout>
		</Layout>
	);
};

export default DefaultLayout;

const ContentWithScrollTop = () => {
	const location = useLocation();

	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		containerRef.current?.scrollTo({
			top: 0,
			left: 0
		});
	}, [location.pathname]);

	return (
		<Content className='p-4 overflow-y-auto' ref={containerRef}>
			<SwitchTransition>
				<CSSTransition key={location.pathname} classNames='fade-global-only' timeout={300} unmountOnExit>
					<Suspense
						fallback={
							<Flex className='w-full h-lvh' justify='center' align='center'>
								<Spin />
							</Flex>
						}
					>
						<Routes location={location}>
							{LayoutRouts.map((route, index) => (
								// eslint-disable-next-line react/no-array-index-key
								<Route key={`default-layout-route-${index}`} path={route.path} element={route.component} />
							))}
						</Routes>
					</Suspense>
				</CSSTransition>
			</SwitchTransition>
		</Content>
	);
};
