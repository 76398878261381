import { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { HttpService } from '../services/http.service';
import { API_URLS } from '../services/apiUrls';
import { ApiHandlersService } from '../services/api-handlers.service';
import { useUtils } from './UtilsProvider';

// Define the category type
export type categoryType = {
	id?: string;
	title: string;
	description: string;
	image: any;
	parentId: string;
	slug: string;
	internalEnum: string;
	titleTranslation?: {};
	descriptionTranslation?: {};
};

// Define the context value type
interface CategoryContextType {
	// isDataLoading: boolean;
	category: any;
	allCategory: any;
	getAllCategory: () => Promise<any>;
	addCategory: (categoryData: categoryType) => Promise<any>;
	getAllSubCategory: (id: string) => Promise<any>;
	updateCategoryById: (id: string, categoryData: categoryType) => Promise<any>;
	deleteCategoryById: (id: string) => Promise<any>;
	getCategoryBySlug: (id: string) => Promise<any>;
	getAllCategoryWithPagination: (params?: any) => Promise<any>;
	deleteSubCategory: (id: string) => Promise<any>;
}

// Create the context
const CategoryContext = createContext<CategoryContextType | undefined>(undefined);

// Define the provider props
interface CategoryProviderProps {
	children: ReactNode;
}

// Create the CategoryProvider component
const CategoryProvider = ({ children }: CategoryProviderProps) => {
	const [category, setCategory] = useState([]); // Paginated
	const [allCategory, setAllCategory] = useState([]); // Not paginated

	const utils = useUtils();

	const getAllCategory = async () => {
		try {
			const res: any = await HttpService.get(API_URLS.ALL_CATEGORIES);

			setAllCategory(res.data.data);
			return res?.data?.data;
		} catch (error) {
			const message = ApiHandlersService.generateErrorMessage(error);
			utils.showError(message);
		}
	};

	const addCategory = async (categoryData: categoryType) => {
		try {
			const response = await HttpService.post(API_URLS.CATEGORY, categoryData as any);

			return response;
		} catch (error) {
			const message = ApiHandlersService.generateErrorMessage(error);
			utils.showError(message);

			throw error;
		}
	};

	const getAllSubCategory = useCallback(
		async (id: string) => {
			try {
				setCategory([]);
				const data: any = await HttpService.get(`${API_URLS.CATEGORY}/${id}/sub-categories`);
				return data;
			} catch (error) {
				const message = ApiHandlersService.generateErrorMessage(error);
				utils.showError(message);
			}
		},
		[utils],
	);

	const updateCategoryById = async (id: string, categoryData: categoryType) => {
		try {
			const data: any = await HttpService.put(`${API_URLS.CATEGORY}/${id}`, categoryData);

			return data;
		} catch (error) {
			const message = ApiHandlersService.generateErrorMessage(error);
			utils.showError(message);

			throw error;
		}
	};

	const deleteCategoryById = async (id: string) => {
		try {
			const data: any = await HttpService.delete(`${API_URLS.CATEGORY}/${id}`);
			return data;
		} catch (error) {
			const message = ApiHandlersService.generateErrorMessage(error);
			utils.showError(message);
		}
	};

	const getCategoryBySlug = async (id: string) => {
		try {
			const data: any = await HttpService.get(`${API_URLS.CATEGORY}/${id}`);
			return data;
		} catch (error) {
			const message = ApiHandlersService.generateErrorMessage(error);
			utils.showError(message);
		}
	};

	const getAllCategoryWithPagination = async (params?: any) => {
		try {
			const res: any = await HttpService.get(API_URLS.CATEGORY, params);
			setCategory(res.data.data);
			return res.data.data;
		} catch (error) {
			const message = ApiHandlersService.generateErrorMessage(error);
			utils.showError(message);
		}
	};

	const deleteSubCategory = async (id: string) => {
		try {
			const data: any = await HttpService.delete(`${API_URLS.CATEGORY}/sub-category/${id}`);
			// getAllSubCategory(id);
			return data;
		} catch (error) {
			const message = ApiHandlersService.generateErrorMessage(error);
			utils.showError(message);
		}
	};

	// Combine all values into a single object
	const values: CategoryContextType = {
		category,
		allCategory,
		getAllCategory,
		addCategory,
		getAllSubCategory,
		updateCategoryById,
		deleteCategoryById,
		getCategoryBySlug,
		getAllCategoryWithPagination,
		deleteSubCategory,
	};

	return <CategoryContext.Provider value={values}>{children}</CategoryContext.Provider>;
};

// Create the useCategory hook
const useCategory = () => {
	const context = useContext(CategoryContext);
	if (!context) {
		throw new Error('useCategory must be used within a CategoryProvider');
	}
	return context;
};

export { CategoryContext, CategoryProvider, useCategory };
