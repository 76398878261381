import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Collapse, Flex, Progress, Spin, Typography } from 'antd';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import { COLORS_PALETTE, GRAY_COLORS_PALETTE } from 'config/colors';
import { generateUniqueKey } from 'helpers';

const WebWorkerContext = createContext<any>(undefined);

type TProvider = {
	children: ReactNode;
};

type TProcess = {
	key: string;
	status: 'running' | 'success' | 'error';
	data: any;
	processDetails: any;
} & ({ status: 'success'; response?: any } | { status: 'error'; error?: any } | { status: 'running'; progressPercent?: string });

export const WebWorkerProvider = ({ children }: TProvider) => {
	const [processes, setProcesses] = useState<{ [key: string]: TProcess }>({});

	const warning = (e: BeforeUnloadEvent) => {
		e.preventDefault();
		// for old browsers
		e.returnValue = true;
	};

	useEffect(() => {
		if (Object.values(processes)?.some((e) => e?.status === 'running')) {
			window.addEventListener('beforeunload', warning);
		} else {
			window.removeEventListener('beforeunload', warning);
		}

		return () => {
			window.removeEventListener('beforeunload', warning);
		};
	}, [JSON.stringify(Object.values(processes)?.map((e) => e?.status))]);

	const startProcess = ({ worker, data, onSuccess, onError }: any) => {
		const onMessage = (event: MessageEvent<TProcess>) => {
			if (event.data.status === 'success') onSuccess?.(event.data?.response);
			if (event.data.status === 'error') onError?.(event.data?.error);

			setProcesses((previousValue: any) => ({
				...(previousValue || {}),
				[event.data.key]: {
					...previousValue[event.data.key],
					status: event.data.status,
					...(event.data.status === 'success' && {
						response: event.data?.response
					}),
					...(event.data.status === 'error' && { error: event.data?.error }),
					...(event.data.status === 'running' && { progressPercent: event.data?.progressPercent })
				}
			}));

			//   worker.terminate();
			if (event.data.status === 'running') return;

			worker.removeEventListener('message', onMessage);
		};

		worker.addEventListener('message', onMessage);

		const uniqueKey = generateUniqueKey();
		worker.postMessage({ data: data.data, key: uniqueKey });

		setProcesses((previousValue: any) => ({
			...(previousValue || {}),
			[uniqueKey]: {
				key: uniqueKey,
				status: 'running',
				data: data.data,
				processDetails: data.processDetails
			}
		}));
	};

	const processesArr = Object.values(processes);

	return (
		// eslint-disable-next-line react/jsx-no-constructed-context-values
		<WebWorkerContext.Provider value={{ startProcess }}>
			<Flex vertical style={{ position: 'relative' }}>
				{children}

				{processesArr.length > 0 && <Processes data={processesArr} setProcesses={setProcesses} />}
			</Flex>
		</WebWorkerContext.Provider>
	);
};

type TUseWorker = {
	worker: Worker;
	onSuccess?: (data?: any) => void;
	onError?: (error?: any) => void;
};

export const useWebWorker = ({ worker, onSuccess, onError }: TUseWorker) => {
	const context = useContext(WebWorkerContext);

	if (context === undefined) {
		throw new Error('useWebWorker must be used within a WebWorkerProvider');
	}

	const { startProcess } = context;

	const startProcessing = (data: any) => {
		startProcess({
			worker,
			data,
			onSuccess,
			onError
		});
	};

	return { startProcessing };
};

type TProcesses = {
	data: any[];
	setProcesses: (processes: any) => void;
};

const Processes = ({ data, setProcesses }: TProcesses) => {
	const [activeKey, setActiveKey] = useState(['1']);

	return (
		<Collapse
			activeKey={activeKey}
			className='mx-2 z-10 bg-white absolute bottom-0 rounded-t-lg rounded-b-none'
			onChange={(key) => {
				setActiveKey(key as string[]);
			}}
		>
			<Collapse.Panel
				header={
					<Flex vertical gap={2}>
						<Typography.Title className='my-0 font-medium text=[12px]' level={5}>
							Uploading...
						</Typography.Title>

						<Typography.Text className='mb-0 font-medium text=[12px]'>Do not refresh the page while uploading.</Typography.Text>
					</Flex>
				}
				key='1'
				extra={
					<Flex gap={8}>
						<Spin indicator={<LoadingOutlined spin />} size='small' />
						{activeKey.some((e) => e === '1') ? <IoIosArrowDown color='#001628' /> : <IoIosArrowUp color='#001628' />}
					</Flex>
				}
				className='w-[400px]'
				showArrow={false}
			>
				<Flex vertical gap={8} className='max-h-[200px] overflow-y-auto'>
					{data.map((process) => (
						<Process key={process.key} data={process} setProcesses={setProcesses} />
					))}
				</Flex>
			</Collapse.Panel>
		</Collapse>
	);
};

const Process = ({ data, setProcesses }: any) => {
	useEffect(() => {
		if (data.status !== 'running') {
			setTimeout(() => {
				setProcesses((previousValue: any) => {
					// eslint-disable-next-line @typescript-eslint/naming-convention
					const { [data.key]: _, ...rest } = previousValue;
					return rest;
				});
			}, 1000);
		}
	}, [data.status]);

	// eslint-disable-next-line no-nested-ternary
	const progressStatus = data?.status === 'success' ? 'success' : data?.status === 'error' ? 'exception' : 'normal';
	const progressPercent = progressStatus === 'success' ? 100 : Number(data.progressPercent || '0');

	return (
		<Flex vertical className='bg-gray-100 p-1 px-2 rounded-md'>
			<Typography.Text>{data.processDetails?.title}</Typography.Text>

			{data?.status === 'error' ? (
				<Typography.Text className='text-red-500 text-[11px]'>{data?.error}</Typography.Text>
			) : (
				<Progress
					type='line'
					percent={progressPercent}
					size='small'
					status={progressStatus}
					strokeColor={COLORS_PALETTE.color200}
					trailColor={GRAY_COLORS_PALETTE.gray200}
				/>
			)}
		</Flex>
	);
};
