import React, { memo, useCallback, useDeferredValue, useEffect, useMemo, useState } from 'react';

import {
	ApartmentOutlined,
	AppstoreOutlined,
	AreaChartOutlined,
	AudioOutlined,
	DatabaseOutlined,
	FileAddOutlined,
	FileImageOutlined,
	FileMarkdownOutlined,
	FileOutlined,
	GroupOutlined,
	HomeOutlined,
	NotificationOutlined,
	OrderedListOutlined,
	PhoneOutlined,
	PlayCircleOutlined,
	ProfileOutlined,
	SettingOutlined,
	UserAddOutlined,
	UserOutlined,
	VideoCameraAddOutlined,
	VideoCameraOutlined
} from '@ant-design/icons';
import { GetProp, Layout, Menu, MenuProps } from 'antd';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { BsCollection } from 'react-icons/bs';
import { GoCommentDiscussion } from 'react-icons/go';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { IoTicketOutline } from 'react-icons/io5';
import { LiaBullhornSolid, LiaPollHSolid, LiaRupeeSignSolid } from 'react-icons/lia';
import { MdOutlineSubscriptions } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';

import { logo } from 'assets/assets';
import { useRolePermissions } from 'services/user-role/permissions/useRolePermissions';
import './sidebar.scss';

const { Sider: LayoutSider } = Layout;

type MenuItem = GetProp<MenuProps, 'items'>[number];

const ANT_DESIGN_ICONS_HASH = {
	HomeOutlined,
	ProfileOutlined,
	UserOutlined,
	OrderedListOutlined,
	FileMarkdownOutlined,
	AreaChartOutlined,
	PlayCircleOutlined,
	AppstoreOutlined,
	FileImageOutlined,
	AudioOutlined,
	VideoCameraOutlined,
	ApartmentOutlined,
	GroupOutlined,
	UserAddOutlined,
	DatabaseOutlined,
	FileOutlined,
	PhoneOutlined,
	SettingOutlined,
	VideoCameraAddOutlined,
	FileAddOutlined,
	NotificationOutlined
} as const;

const REACT_ICONS_HASH = {
	LiaPollHSolid,
	BsCollection,
	GoCommentDiscussion,
	IoTicketOutline,
	MdOutlineSubscriptions,
	LiaRupeeSignSolid,
	IoMdNotificationsOutline,
	LiaBullhornSolid
} as const;

type TIconName = keyof typeof ANT_DESIGN_ICONS_HASH | keyof typeof REACT_ICONS_HASH;

function getItem(label: React.ReactNode, key?: React.Key | null, iconName?: TIconName, children?: MenuItem[]): MenuItem {
	let IconRef: any = null;
	if (iconName && iconName in ANT_DESIGN_ICONS_HASH) {
		IconRef = ANT_DESIGN_ICONS_HASH[iconName as keyof typeof ANT_DESIGN_ICONS_HASH];
		IconRef = <IconRef className='if-cms-sidebar-icon' />;
	}
	if (iconName && iconName in REACT_ICONS_HASH) {
		IconRef = REACT_ICONS_HASH[iconName as keyof typeof REACT_ICONS_HASH];
		IconRef = (
			<span className='if-cms-sidebar-icon'>
				<IconRef />
			</span>
		);
	}

	return {
		key,
		icon: IconRef,
		children,
		label
	} as MenuItem;
}

const getMenuItemsArray = (showUsers: boolean, showAdmin: boolean, t: TFunction): MenuItem[] => {
	return [
		getItem(t('dashboard.dashboard'), '', 'HomeOutlined'),
		showUsers
			? getItem(t('user.users'), 'users', 'ProfileOutlined', [
					showAdmin ? getItem(t('user.users'), 'users', 'UserOutlined') : null,
					getItem('Authors', 'authors', 'OrderedListOutlined')
				])
			: null,
		getItem('Master', 'master', 'FileMarkdownOutlined', [
			getItem('Categories', 'categories', 'ApartmentOutlined'),
			getItem('Districts', 'districts', 'AreaChartOutlined'),
			getItem('Credits', 'credits', 'ProfileOutlined'),
			getItem('Collections', 'collections', 'BsCollection')
		]),
		getItem('Media', 'media', 'AppstoreOutlined', [
			getItem('Images', 'images', 'FileImageOutlined'),
			getItem('Audios', 'audios', 'AudioOutlined'),
			getItem('Videos', 'videos', 'VideoCameraOutlined'),
			getItem('Live Streams', 'live-streams', 'PlayCircleOutlined')
		]),
		getItem('Political Pulse', 'political-pulse', 'ApartmentOutlined', [
			getItem('Parties', 'parties', 'GroupOutlined'),
			getItem('Leaders', 'leaders', 'UserAddOutlined'),
			getItem('Propaganda', 'propaganda', 'LiaBullhornSolid')
		]),
		getItem('Content', 'content', 'DatabaseOutlined', [
			getItem('Articles', 'articles', 'FileOutlined'),
			getItem('Podcasts', 'podcasts', 'PhoneOutlined'),
			getItem('Movies', 'movies', 'VideoCameraOutlined'),
			getItem('Series', 'series', 'VideoCameraOutlined'),
			getItem('Dynamic Pages', 'dynamic-pages', 'FileAddOutlined')
		]),
		getItem('Polls', 'polls', 'LiaPollHSolid'),
		getItem('Community Rooms', 'community-rooms', 'GoCommentDiscussion'),
		getItem('Raffles', 'raffles', 'IoTicketOutline'),
		getItem('Subscriptions', 'subscriptions', 'LiaRupeeSignSolid'),
		getItem('Notifications', 'notifications', 'IoMdNotificationsOutline'),

		getItem('Settings', 'setting', 'SettingOutlined', [
			getItem('Profile', 'user-profile', 'UserOutlined'),
			getItem('Change Password', 'change-password', 'ProfileOutlined')
		])
	];
};

const Sidebar = memo(() => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { hasPermission } = useRolePermissions();

	const [collapsed, setCollapsed] = useState(false);

	const deferredCollapsed = useDeferredValue(collapsed);

	// 0.02 -> 0.001
	const handleCollapse = useCallback((val: boolean) => {
		setCollapsed(val);
	}, []);

	const showUsers = hasPermission('users.show');
	const showAdmin = hasPermission('users.admin_show');

	// before 0.156982421875 ms // after 0.0029296875 ms
	const menuItems = useMemo(() => getMenuItemsArray(showUsers, showAdmin, t), [t]);

	const { pathname } = useLocation();

	const selectedKeys = useMemo(() => pathname.replace('/', ''), [pathname]);

	const onClick: MenuProps['onClick'] = (e) => {
		navigate(`/${e.key}`);
	};

	const [openKeys, setOpeners] = useState<string[]>([]);

	// to open setting sub menu when user enter profile thru header.
	useEffect(() => {
		if (pathname === '/user-profile') {
			setOpeners((prev) => [...prev, 'setting']);
		}
		// ? apply to other submenu as well?
	}, [pathname]);

	return (
		<LayoutSider collapsible collapsed={deferredCollapsed} onCollapse={handleCollapse} width={220} id='if-cms-layout-sider'>
			<div
				className='if-cms-logo'
				onClick={() => {
					navigate('/');
				}}
			>
				<img src={logo} alt='logo' />
			</div>

			<div className={['overflow-y-auto fixed top-20 bottom-12 bg-white', `${collapsed ? 'w-20' : 'w-[220px]'}`].join(' ')}>
				<Menu
					mode='inline'
					theme='light'
					items={menuItems}
					onClick={onClick}
					className='mb-6'
					selectedKeys={[selectedKeys]}
					openKeys={openKeys}
					onOpenChange={(e) => {
						setOpeners(e);
					}}
				/>
			</div>
		</LayoutSider>
	);
});

export default Sidebar;
