import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { languageOptions } from 'config/constants';
import { StorageService } from 'services/storage.service';
import { EStorageKeys } from 'types/enums';

const HeaderTranslation = () => {
	const { i18n } = useTranslation();

	const defaultLanguage = StorageService.get(EStorageKeys.TRANSLATION_LANGUAGE) || i18n.language;

	const handleLanguageChange = (value: string) => {
		i18n.changeLanguage(value);
		StorageService.set(EStorageKeys.TRANSLATION_LANGUAGE, value);
	};

	return <Select defaultValue={defaultLanguage} onChange={handleLanguageChange} className='mr-8 w-32' options={languageOptions} />;
};

export default HeaderTranslation;
